<template>
  <div>
    <v-dialog
      elevation-0

      v-model="dialog"
      scrollable
      max-width="1200px"
    >
      <make-payment
        v-if="contact_id"
        :key="payment_key"
        :property_id="property_id"
        :contact_id="contact_id"
        @close="closeMakePayment"
        @reload="payment_key++"
      >
      </make-payment>
    </v-dialog>
  </div>

</template>

<script type="text/babel">
    import Status from './includes/Messages.vue';
    import Loader from './assets/CircleSpinner.vue';
    import api from '../assets/api.js';
    import MakePayment from './includes/MakePayment/Index.vue';

    export default {
        name: "PayNow",
        data() {
            return {
                dialog: false,
                payment_key: 0,
                property_id: '',
                contact_id: ''
            }
        },
        async created(){
            await this.parseToken();
        },
        components: {
            Loader,
            Status,
            MakePayment
        },
        methods:{
            async parseToken(){
                let r = await api.get(this, api.CONTACTS + 'parse-pay-now-token/' + this.$route.params.token);
                this.property_id = r.property_id;
                this.contact_id = r.contact_id;
                this.dialog = true;
            },
            closeMakePayment(){

            }
        }
    }

</script>
